import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
import 'wow.js/css/libs/animate.css';
import '@/assets/css/system.scss';
import wow from 'wow.js'// 动态效果样式文件
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import i18n from '@/i18n/index';
import VueI18n from "vue-i18n";
Vue.use(VueI18n);
Vue.use(ElementUI)
// import 'lib-flexible/flexible.js'
// import 'amfe-flexible';




Vue.config.productionTip = false
Vue.prototype.$wow = wow
new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
