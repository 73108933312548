import VueI18n from "vue-i18n";
import Vue from 'vue';

import enLocale from 'element-ui/lib/locale/lang/en'
import idLocale from 'element-ui/lib/locale/lang/id'
import GB from './lang/en';
import ID from './lang/id';
Vue.use(VueI18n)

const messages = {
    en: {
        ...GB,
        ...enLocale
    },
    id: {
        ...ID,
        ...idLocale
    }
}
const lang = localStorage.getItem('lang') || 'GB';

const i18n = new VueI18n({
    locale: lang, // set locale
    messages, // set locale messages
});
export default i18n