<template>
  <div :class="$store.state.style ==='black' ? 'footer': 'footer normal'">
    <div class="WebMain">
      <div class="top">
        <div class="left">
          <div class="title">
            <p class="p26">Contact Us</p>
            <p></p>
          </div>
          <div class="item" v-for="item in contactInfo" :key="item.id">
            <img v-show="$store.state.style === 'black'"
              :src="require(`../../assets/img/footer/${item.icon}`)"
              alt=""
            />
            <img v-show="$store.state.style === 'white'"
              :src="require(`../../assets/img/footer/${item.icon1}`)"
              alt=""
            />
            <p class="p16">{{ item.info }}</p>
          </div>
        </div>
        <div class="right">
          <p class="title p22">Is Outbox Information Required?</p>
          <div class="item1">
            <input type="text" placeholder="Name" v-model="submitInfo.name" />
            <input type="text" disabled placeholder="recipient Akina@tradewisesoftware.com" v-model="submitInfo.eMail" />
          </div>
          <div class="item2">
            <input type="text" placeholder="Message" v-model="submitInfo.message" />
          </div>
          <div class="btn" >
            <p class="p18">
              <a :href="`mailto:Akina@tradewisesoftware.com?@hotmail.com&name=${submitInfo.name}&message=${submitInfo.message}`">SEND MAIL</a>
              
            </p>
          </div>
        </div>
      </div>
      <div class="bottom">
        <p class="p18">TradeWise Software PTE. Limited.是在新加坡注册的有限公司，本网站及其内容的版权归TradeWise Software PTE. Limited.所有</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      contactInfo: [
        { id: 1, icon: "icon (1).png",
        icon1: "icon1 (1).png",
         info: "Tel:  0065 31388988" },
        {
          id: 2,
          icon: "icon (2).png",
          icon1: "icon1 (2).png",
          info: "Email: Akina@tradewisesoftware.com",
        },
        {
          id: 3,
          icon: "icon (3).png",
          icon1: "icon1 (3).png",
          info: "Address: 987 Serangoon Road, Singapore, 328147",
        },
      ],
      submitInfo: {
        name: "",
        eMail: "",
        message: "",
      },
    };
  },
  methods: {
    submit(){
      console.log(this.submitInfo)
    }
  }
};
</script>

<style lang="scss" scoped>
.footer {
  // background: url("../../assets/img/footer/编组\ 2-min.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding-top: 54px;
  padding-bottom: 35px;
  // color: #fff;
  .top {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .left {
      .title {
        margin-bottom: 42px;
        p {
          font-weight: bold;
          line-height: 31px;
          &:last-child {
            width: 80px;
            height: 2px;
            background: var(--custom-color);
            margin-top: 16px;
          }
        }
      }
      .item {
        display: flex;
        align-items: center;
        margin-bottom: 30px;
        img {
          height: 42px;
          width: 42px;
          margin-right: 18px;
        }
      }
    }
    .right {
      width: 630px;
      input {
        background: transparent !important;
        border: none !important;
        border-bottom: 1px solid #fff !important;
        font-size: 20px;
        // color: white;
        &::placeholder {
          // color: white;
          font-size: 16px;
        }
        &:focus-within{
        outline: none !important;
        }
      }
      .item1{
        display: flex;
        justify-content: space-between;
        height: 76px;
        margin-top: 15px;
        input{
          width: 230px;
          line-height: 76px;
          &:last-child{
            width: 310px;
          }
        }
      }
      .item2{
        height: 104px;
        input{
          width: 100%;
          height: 104px;
        }
      }
      .btn {
        margin-top: 30px;
        width: 200px;
        height: 44px;
        line-height: 44px;
        text-align: center;
        background: var(--custom-color);
        border-radius: 7px;
        cursor: pointer;
        color: #fff;
        p {
          
font-family: Helvetica;
          font-weight: 600;
        }
      }
    }
  }
  .bottom {
    text-align: center;
    line-height: 24px;
    margin-top: 85px;
  }
}
.normal .top .right input{
  
    border-bottom: 1px solid #414141 !important;
  
}
@media screen and (max-width: 800px){
  .footer .top .right {
    margin-top: 30px;
    .item1{
    height: auto;
    flex-wrap: wrap;
  }
  }
}

</style>