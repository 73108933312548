import request from '@/utils/request'

import store from '@/store'
store.dispatch('getAPI');

// var info = store.state.APIinfo;
var info = JSON.parse(sessionStorage.getItem('APIinfo'));
// 修改API设置
export function updateAPISettingsAPI(data) {
    return request({
        url: `/setting/updateApiSetting?ct=${info.ct}&jpressAppId=${info.jpressAppId}&sign=${info.sign}`,
        method: 'post',
        data
    });
}

// 获取API设置详情
export function APISettingsAPI(params) {
    let newParam = {
        ...params,
        ...info
    };
    return request({
        url: '/setting/findApiSettingById',
        method: 'get',
        params: newParam
    });
}
export function sendMail(data) {
    return request({
        url: `/`,
        method: 'get',
        params:data
    });
}