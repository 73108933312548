import Vue from 'vue'
import Vuex from 'vuex'
import md5 from 'js-md5'
import request from '@/utils/request'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isPhone: false, // 手机还是电脑
    requestAPI: 'https://test.tradewisesoftware.com/api',
    isCollapse: false, // 控制菜单的展开与收起
    APIinfo: {}, // 请求需要的加密信息,
    bannerInfo: {
      imgPath: '',
      text: {}
    },
    style: 'black', // 黑色模式和白色
  },
  mutations: {
    changeIsPhone(state,val) {
      state.isPhone = val
      // console.log(state.isPhone)
    },
    // 控制侧边栏的展开收起
    changeCollapse(state, val) {
      // console.log(state)
      state.isCollapse = !state.isCollapse;
    },
    // 接口设置的信息
    getApiInfo(state, val) {
      // console.log(val)
      let info = {};
      // console.log(info)
      info.ct = Date.now();
      // info.jpressAppId = val.value;
      // const secret = val.siteId;
      info.jpressAppId = 'admin';
      // console.log(info)
      const secret = '123456';
      const keys = Object.keys(info);
      // console.log(info)
      keys.sort();
      let str = '';
      for (const i of keys) {
        str += i + info[i];
      }
      str += secret;

      let sign = md5(window.encodeURI(str));
      info.sign = sign;
      // console.log(info)
      state.APIinfo = info;
      sessionStorage.setItem('APIinfo', JSON.stringify(info))
      // console.log(state.APIinfo)
    },
    // 改变banner数据
    changeBannerInfo(state, val) {
      state.bannerInfo.imgPath = val;
    },
    changeBannerText(state, val) {
      state.bannerInfo.text = val;
    },
    changeStyle(state, val) {
      state.style = val;
    }
  },
  actions: {
    // 获取接口设置的信息
    async getAPI(context) {
      // const res = await request({
      //   url: `/setting/findApiSettingById`,
      //   method: 'get',
      //   // params: 
      // });
      // console.log(res);
      context.commit('getApiInfo')
    },
  },
  modules: {
  }
})
