import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/management/Home.vue'
import WebIndex from "@/views/web/Index.vue"
import chineseIndex from '@/views/ChineseWeb/Index.vue'
Vue.use(VueRouter)
import {
  Message
} from 'element-ui';
const routes = [{
    path: '/',
    name: 'WebIndex',
    component: WebIndex,
    redirect: '/home',
    children: [{
        path: 'home',
        name: 'Home',
        component: () => import('../views/web/Home.vue')
      },
      {
        path: 'product',
        name: 'product',
        component: () => import('../views/web/Product.vue')
      }
    ]
  },
  {
    path: '/chinese',
    name: 'chineseIndex',
    component: chineseIndex,
    redirect: '/home',
    children: [{
        path: 'home',
        name: 'Home',
        component: () => import('../views/ChineseWeb/Home.vue')
      },
      {
        path: 'product',
        name: 'product',
        component: () => import('../views/ChineseWeb/Product.vue')
      }
    ]
  },
  {
    path: '/admin/login',
    name: 'Login',
    component: () => import( /* webpackChunkName: "about" */ '@/views/management/Login.vue')
    // redirect: '/'
  },
  {
    path: '/admin',
    name: 'admin',
    component: Home,
    beforeEnter: async (to, from, next) => {
      // 获取本地的 token
      const token = sessionStorage.token;
      if (token) {
        next();
        // }
      } else {
        Message({
          showClose: true,
          message: '你还未登录，请先登录！'
        });
        // alert("你还未登录，请先登录"); // 可以根据项目的 UI 框架来设置弹框提示
        next("/admin/login");
      }
    },
    children: [{
        path: 'updatePassword',
        name: 'UpdatePassword',
        component: () => import( /* webpackChunkName: "about" */ '../views/management/user/UpdatePass.vue'),

      },
      // 中文版文章模块
      {
        path: 'article/list',
        name: 'ArticleList',
        component: () => import( /* webpackChunkName: "about" */ '../views/management/article/Index.vue'),
        meta: {
          breadcrumbList: [{
              id: 1,
              name: '首页'
            },
            {
              id: 2,
              name: '文章'
            },
            {
              id: 3,
              name: '文章管理'
            }
          ],
          info: '可查看编辑文章'
        }
      },
      {
        path: 'article/write',
        name: 'ArticleWrite',
        component: () => import( /* webpackChunkName: "about" */ '../views/management/article/Write.vue'),
        meta: {
          breadcrumbList: [{
              id: 1,
              name: '首页'
            },
            {
              id: 2,
              name: '文章'
            },
            {
              id: 3,
              name: '写文章'
            }
          ]
        }
      },
      {
        path: 'article/type',
        name: 'ArticleType',
        component: () => import( /* webpackChunkName: "about" */ '../views/management/article/Type.vue'),
        meta: {
          breadcrumbList: [{
              id: 1,
              name: '首页'
            },
            {
              id: 2,
              name: '文章'
            },
            {
              id: 3,
              name: '文章分类'
            }
          ]
        }
      },
      {
        path: 'article/slug',
        name: 'ArticleSlug',
        component: () => import( /* webpackChunkName: "about" */ '../views/management/article/Label.vue'),
        meta: {
          breadcrumbList: [{
              id: 1,
              name: '首页'
            },
            {
              id: 2,
              name: '文章'
            },
            {
              id: 3,
              name: '文章标签'
            }
          ]
        }
      },
      // 英语版文章模块
      {
        path: 'EnglishArticle/list',
        name: 'EnglishArticleList',
        component: () => import( /* webpackChunkName: "about" */ '../views/management/EnglishArticle/Index.vue'),
        meta: {
          breadcrumbList: [{
              id: 1,
              name: '首页'
            },
            {
              id: 2,
              name: '文章'
            },
            {
              id: 3,
              name: '文章管理'
            }
          ],
          info: '可查看编辑文章'
        }
      },
      {
        path: 'EnglishArticle/write',
        name: 'EnglishArticleWrite',
        component: () => import( /* webpackChunkName: "about" */ '../views/management/EnglishArticle/Write.vue'),
        meta: {
          breadcrumbList: [{
              id: 1,
              name: '首页'
            },
            {
              id: 2,
              name: '文章'
            },
            {
              id: 3,
              name: '写文章'
            }
          ]
        }
      },
      {
        path: 'EnglishArticle/type',
        name: 'EnglishArticleType',
        component: () => import( /* webpackChunkName: "about" */ '../views/management/EnglishArticle/Type.vue'),
        meta: {
          breadcrumbList: [{
              id: 1,
              name: '首页'
            },
            {
              id: 2,
              name: '文章'
            },
            {
              id: 3,
              name: '文章分类'
            }
          ]
        }
      },
      {
        path: 'EnglishArticle/slug',
        name: 'EnglishArticleSlug',
        component: () => import( /* webpackChunkName: "about" */ '../views/management/EnglishArticle/Label.vue'),
        meta: {
          breadcrumbList: [{
              id: 1,
              name: '首页'
            },
            {
              id: 2,
              name: '文章'
            },
            {
              id: 3,
              name: '文章标签'
            }
          ]
        }
      },
      // 繁体版文章模块
      {
        path: 'TCCArticle/list',
        name: 'TCCArticleList',
        component: () => import( /* webpackChunkName: "about" */ '../views/management/TCCArticle/Index.vue'),
        meta: {
          breadcrumbList: [{
              id: 1,
              name: '首页'
            },
            {
              id: 2,
              name: '文章'
            },
            {
              id: 3,
              name: '文章管理'
            }
          ],
          info: '可查看编辑文章'
        }
      },
      {
        path: 'TCCArticle/write',
        name: 'TCCArticleWrite',
        component: () => import( /* webpackChunkName: "about" */ '../views/management/TCCArticle/Write.vue'),
        meta: {
          breadcrumbList: [{
              id: 1,
              name: '首页'
            },
            {
              id: 2,
              name: '文章'
            },
            {
              id: 3,
              name: '写文章'
            }
          ]
        }
      },
      {
        path: 'TCCArticle/type',
        name: 'TCCArticleType',
        component: () => import( /* webpackChunkName: "about" */ '../views/management/TCCArticle/Type.vue'),
        meta: {
          breadcrumbList: [{
              id: 1,
              name: '首页'
            },
            {
              id: 2,
              name: '文章'
            },
            {
              id: 3,
              name: '文章分类'
            }
          ]
        }
      },
      {
        path: 'TCCArticle/slug',
        name: 'TCCArticleSlug',
        component: () => import( /* webpackChunkName: "about" */ '../views/management/TCCArticle/Label.vue'),
        meta: {
          breadcrumbList: [{
              id: 1,
              name: '首页'
            },
            {
              id: 2,
              name: '文章'
            },
            {
              id: 3,
              name: '文章标签'
            }
          ]
        }
      },
      {
        path: 'attachment/list',
        name: "attachmentList",
        component: () => import('../views/management/attachment/Index.vue'),
        meta: {
          breadcrumbList: [{
              id: 1,
              name: '首页'
            },
            {
              id: 2,
              name: '附件'
            },
            {
              id: 3,
              name: '附件列表'
            }
          ]
        }
      },
      {
        path: 'attachment/upload',
        name: "attachmentUpload",
        component: () => import('../views/management/attachment/Upload.vue'),
        meta: {
          breadcrumbList: [{
              id: 1,
              name: '首页'
            },
            {
              id: 2,
              name: '附件'
            },
            {
              id: 3,
              name: '上传'
            }
          ]
        }
      },
      {
        path: 'attachment/classify',
        name: "attachmentClassify",
        component: () => import('../views/management/attachment/Classify.vue'),
        meta: {
          breadcrumbList: [{
              id: 1,
              name: '首页'
            },
            {
              id: 2,
              name: '附件'
            },
            {
              id: 3,
              name: '分类管理'
            }
          ]
        }
      },
      {
        path: 'user/list',
        name: "userList",
        component: () => import('@/views/management/user/List.vue'),
        meta: {
          breadcrumbList: [{
              id: 1,
              name: '首页'
            },
            {
              id: 2,
              name: '用户'
            },
            {
              id: 3,
              name: '用户管理'
            }
          ]
        }
      },
      {
        path: 'user/editUser',
        name: "userEditUser",
        component: () => import('@/views/management/user/EditUser.vue'),
        meta: {
          breadcrumbList: [{
              id: 1,
              name: '首页'
            },
            {
              id: 2,
              name: '用户'
            },
            {
              id: 3,
              name: '编辑用户'
            }
          ]
        }
      },
      {
        path: 'settings/basic',
        name: "settingsBasic",
        component: () => import('@/views/management/settings/Basic.vue'),
        meta: {
          breadcrumbList: [{
              id: 1,
              name: '首页'
            },
            {
              id: 2,
              name: '设置'
            },
            {
              id: 3,
              name: '基础设置'
            }
          ]
        }
      },
      // 中文菜单设置
      {
        path: 'settings/menu',
        name: "settingsMenu",
        component: () => import('@/views/management/settings/Menu.vue'),
        meta: {
          breadcrumbList: [{
              id: 1,
              name: '首页'
            },
            {
              id: 2,
              name: '设置'
            },
            {
              id: 3,
              name: '菜单设置'
            }
          ]
        }
      },
      // 英文菜单设置
      {
        path: 'settings/EnglishMenu',
        name: "settingsEnglishMenu",
        component: () => import('@/views/management/settings/EnglishMenu.vue'),
        meta: {
          breadcrumbList: [{
              id: 1,
              name: '首页'
            },
            {
              id: 2,
              name: '设置'
            },
            {
              id: 3,
              name: '菜单设置'
            }
          ]
        }
      },
      // 繁体菜单设置
      {
        path: 'settings/TCCmenu',
        name: "settingsTCCmenu",
        component: () => import('@/views/management/settings/TCCMenu.vue'),
        meta: {
          breadcrumbList: [{
              id: 1,
              name: '首页'
            },
            {
              id: 2,
              name: '设置'
            },
            {
              id: 3,
              name: '菜单设置'
            }
          ]
        }
      },
      {
        path: 'settings/api',
        name: "settingsAPI",
        component: () => import('@/views/management/settings/ApiSetting.vue'),
        meta: {
          breadcrumbList: [{
              id: 1,
              name: '首页'
            },
            {
              id: 2,
              name: '设置'
            },
            {
              id: 3,
              name: '接口设置'
            }
          ]
        }
      },
      {
        path: 'settings/SEO',
        name: "settingsSEO",
        component: () => import('@/views/management/settings/SEOSetting.vue'),
        meta: {
          breadcrumbList: [{
              id: 1,
              name: '首页'
            },
            {
              id: 2,
              name: '设置'
            },
            {
              id: 3,
              name: 'SEO设置'
            }
          ]
        }
      },
    ]
  },

]

const router = new VueRouter({
  routes,
  // return 期望滚动到哪个的位置
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      // vue2.0  x  y  控制
      // vue3.0  left  top 控制
      return {
        x: 0,
        y: 0
      }
    }
  }
})

export default router